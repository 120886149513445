import { ref } from '@vue/composition-api'
import store from '@/store'
import http from '@/global/http'

export default function useUsersList() {
  const isCollapsedCard = ref({
    pkh: false,
    dpc: false,
  })
  const roles = ref([])
  const role = ref({})

  // Table Handlers
  const tableColumns = [
    {
      label: 'Tên nhóm',
      key: 'name',
    },
    {
      label: 'Mã',
      key: 'code',
    },
    {
      label: '',
      key: 'actions',
    },
  ]

  const fetchRoles = filter => {
    const queryString = http.buildQuery(filter || {
      page: 0,
      perpage: 999,
    }) || ''
    return http.handle(store.dispatch('role/fetchRoles', queryString), res => {
      roles.value = res.data.sort((a, b) => (a.id < b.id ? 1 : -1))
    })
  }

  const fetchRole = id => http.handle(store.dispatch('role/fetchRole', { id }), res => {
    role.value = res.data
  })

  const createRole = data => http.handle(store.dispatch('role/createRole', data), res => {
    roles.value.unshift(res.data)
  })

  const updateRole = data => http.handle(store.dispatch('role/updateRole', data), res => {
    Object.assign(roles.value.find(u => u.id === res.data.id), res.data)
  })

  const deleteRole = user => http.handle(store.dispatch('role/deleteRole', user), () => {
    const idx = roles.value.findIndex(u => u.id === user.id)
    roles.value.splice(idx, 1)
  })

  return {
    tableColumns,
    fetchRole,
    updateRole,
    createRole,
    deleteRole,
    isCollapsedCard,
    roles,
    fetchRoles,
  }
}

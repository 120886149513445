import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRoles: (ctx, q) => axiosIns.get(`/api.role${q || ''}`),
    fetchPermissions: () => axiosIns.get('/api.meta/permission'),
    fetchRole: (ctx, { id }) => axiosIns.get(`/api.role/${id}`),
    createRole: (ctx, data) => axiosIns.post('/api.role', data),
    updateRole: (ctx, data) => axiosIns.put(`/api.role/${data.id}`, data),
    deleteRole: (ctx, { id }) => axiosIns.delete(`/api.role/${id}`),
  },
}
